<template>
	<div class="app-main__inner">
		<div class="container rounded p-3">
			<div class="flex flex-col md:flex-row justify-between align-items-center ">
				<div class="invite-header w-full">
					<div class="flex justify-between align-items-center space-x-3 mb-3">
						<span class="text-sm md:text-2xl font-semibold text-gray-900 dashboard-head-size">{{
								$t('subscription')
							}}</span>
						<div class="flex" v-if="loadingCompany === false">

						</div>
					</div>
				</div>

			</div>
			<div data-cy='subscription-box' class="row dm-sans-font p-3">
				<div class="col-md-3 col-xl-3 mb-3">
					<div class="card p-3 bg-theme-blue rounded-lg">
						<div class="flex align-items-center space-x-4">
							<div class="">
								<img src="./../../assets/images/icons/money.png" alt="">
							</div>
							<div class="flex flex-col">
								<div class="font-normal text-white text-sm whitespace-nowrap">
									{{ $t('currentSubscription') }}
								</div>
								<span class="text-2xl text-white font-semibold"
									  v-if="GET_USER_SUBSCRIPTION.subscription_type">{{
										GET_USER_SUBSCRIPTION.subscription_type.name
									}}</span>
								<span class="text-2xl text-white font-semibold" v-else>{{ $t('notAvailable') }}</span>
							</div>
						</div>
					</div>
				</div>
				<div class="col-md-3 col-xl-3 mb-3">
					<div class="card p-3 bg-theme-yellow rounded-lg">
						<div class="flex align-items-center space-x-4">
							<div class="">
								<img src="./../../assets/images/icons/money.png" alt="">
							</div>
							<div class="flex flex-col">
								<div class="font-normal text-white text-sm whitespace-nowrap">{{
										$t('monthlyCost')
									}}
								</div>
								<span class="text-2xl text-white font-semibold" id="total-orders-month"
									  v-if="GET_USER_SUBSCRIPTION.subscription_type">{{
										$services.helpers.animateValue('total-orders-month', 0, GET_USER_SUBSCRIPTION.subscription_type.monthly_cost || 0, 4000)
									}}</span>
								<span class="text-2xl text-white font-semibold" id="total-orders-month"
									  v-else>{{ $t('notAvailable') }}</span>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="row bg-white p-3 dm-sans-font">
				<div class="col-md-12 flex justify-center" v-if="loading">
					<svg class="animate-spin mr-2 h-8 w-8 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none"
						 viewBox="0 0 24 24">
						<circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
								stroke-width="4"></circle>
						<path class="opacity-75" fill="currentColor"
							  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
					</svg>
				</div>
				<div class="col-md-3" v-for="subscription in GET_SUBCRIPTION_TYPE" :key="subscription.id">
					<div class="price-box border"
						 :class="subscription.name === 'BASIC' ? 'card-side-4 text-white' : 'text-color-deep'">
						<div class="ribbon blue" v-if="subscription.name === 'STANDARD'"><span>Populärast</span></div>
						<ul class="pricing-list dm-sans-font"
							:class="subscription.name === 'BASIC' ? 'text-white' : ''">
							<li class="price-title text-xs">{{ subscription.name }}</li>
							<li class="price-value text-4xl"
								:class="subscription.name === 'BASIC' ? 'text-white' : 'text-yellow'">
								{{ subscription.monthly_cost === 0 ? 'Gratis' : subscription.monthly_cost }}
							</li>
							<li class="price-subtitle dm-sans-font whitespace-nowrap">
								{{ subscription.monthly_cost === 0 ? 'Ingen månadskostnad' : 'per månad' }}
							</li>
							<li class="price-text flex align-items-center whitespace-nowrap"
								v-for="(feature, i) in subscription.features" :key="i">
								<img class="h-4 mr-2" src="./../../assets/images/icons/check-white.svg" alt=""
									 v-if="subscription.name === 'BASIC'">
								<img class="h-4 mr-2" src="./../../assets/images/icons/check.svg" alt=""
									 v-else>{{ feature }}
							</li>
							<li class="price-tag-line mt-3">
								<button :id="'btn-'+subscription.id"
										:class="subscription.name === 'BASIC' ? 'bg-white text-blue-900 font-semibold' : 'bg-yellow-400 text-white focus:bg-yellow-500'"
										class="sub-btn w-full py-2 text-xl rounded shadow-sm disabled:opacity-50"
										@click="selectSubscription(subscription.id)">Beställ
								</button>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
	name: 'dashboard-single',
	data() {
		return {
			loading: false,
			processing: false,
			company: {},
			loadingCompany: true,
		}
	},
	computed: {
		...mapGetters({
			GET_SUBCRIPTION_TYPE: 'portal/GET_SUBCRIPTION_TYPE',
			GET_USER_SUBSCRIPTION: 'portal/GET_USER_SUBSCRIPTION',
			GET_LOCALE: 'utility/GET_LOCALE_LANG'
		}),
		locale_message: function () {
			return this.GET_LOCALE === 'se' ? 'message_swe' : 'message'
		}
	},
	mounted() {
		this.getCompanyDetails()
		this.getUserSubscription()


	},
	methods: {
		getSubscription(query = ``) {
			this.loading = true
			this.$store.dispatch('portal/getUserSubscriptionType', {type: query})
				.then(_ => {
					this.loading = false
				})
				.catch(_ => {
					this.loading = false
				})
		},
		getUserSubscription() {
			this.$store.dispatch('portal/getUserSubscription').then(res => {
				this.getSubscription(`supplier_type?type=${res.data.data[0].subscription_type.type}`)
			})
		},
		selectSubscription(id) {
			// console.log('what id:', id)
			const clickButton = document.getElementById('btn-' + id)
			const allBtn = document.querySelectorAll('button')
			allBtn.forEach(element => {
				element.disabled = true
			})
			clickButton.innerHTML = `
                <div class='flex justify-between align-items-center whitespace-nowrap'>
                    <svg class="animate-spin mr-2 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    Vänligen vänta
                </div>`

			this.$store.dispatch('portal/updateUserSubscription', {current: this.GET_USER_SUBSCRIPTION.id, sub: id})
				.then(_ => {
					this.$services.helpers.notification(this.$t('subscriptionSuccess'), 'success', this)
					clickButton.innerText = 'Beställ'
					setTimeout(() => {
						allBtn.forEach(element => {
							element.disabled = false
						})
					}, 1800)
				})
				.catch(err => {
					allBtn.forEach(element => {
						element.disabled = false
					})
					clickButton.innerText = 'Beställ'
					this.$services.helpers.notification(err.response.data[this.locale_message], 'error', this)
					if (err.response.status === 401) {
						this.$store.commit('auth/CLEAR_AUTH_USER', null)
						window.Bus.$emit('sign-out')
					}
				})
		},
		getCompanyDetails() {
			this.loadingCompany = true
			this.$store.dispatch('portal/getCompanyDetails')
				.then(res => {
					this.company = res.data
					this.loadingCompany = false
				})
				.catch(err => {
					this.loadingCompany = true
					if (err.response.status === 401) {
						this.$store.commit('auth/CLEAR_AUTH_USER', null)
						window.Bus.$emit('sign-out')
					}
				})
		},
		onConnect: function () {
			let payload = {'company_id': this.company.id}
			this.processing = true
			this.$store.dispatch('auth/getAuthPermissionUrl', {'data': payload}).then(resp => {
				this.processing = false
				window.open(resp.data.url, '_blank')
			}).catch(err => {
				this.processing = false
				window.Bus.$emit('output-error', err)
			})
		}
	}
}
</script>

<style lang="scss" scoped>
@import "./../../assets/variables.scss";
@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200&display=swap');

.bg-custom-theme-blue {
	background: #8AACDE !important;
}

.bg-theme-pink {
	background: #FEF1F5;
	border: 0.5px solid $theme-primary-color;
}

.bg-theme-blue {
	background: #303DB7;
	border: 0.5px solid #303DB7;
}

.bg-theme-yellow {
	background: #E8B105;
	border: 0.5px solid #E8B105;
}

.text-yellow {
	color: #E8B105;
}

.text-color-deep {
	color: $production-theme !important;
}

.bg-theme-green {
	background: #12CA5B;
	border: 0.5px solid #12CA5B;
}

.text-tertiary {
	color: $theme-tertiary-color !important;
}

.text-secondary {
	color: $theme-secondary-color !important;
}

.text-color-theme {
	color: $theme-primary-color !important;
}

.bg-custom-theme-teal {
	background: #85CED2 !important;
}

.bg-custom-theme-green {
	background: #BBE5AC !important;
}

.price-box {
	// text-align: center;
	background-color: #fff;
	border-bottom: 3px solid #e0e0e0;
	box-shadow: rgba(33, 33, 33, .06) 0 4px 24px 5px;
	padding: 40px 0 30px;
	margin-bottom: 20px;
	border-radius: 8px 8px;
	transition: all .3s ease-in-out;
	// -webkit-transition: all .3s ease-in-out;
	// -webkit-backface-visibility: hidden;
	-moz-osx-font-smoothing: grayscale;
}

ul.pricing-list {
	padding: 0 25px;
}

ul, ol {
	list-style: none;
	margin: 0;
	padding: 0;
	// color: #858585;
	font-size: 14px;
	line-height: 24px;
	margin-bottom: 20px;
}

ul.pricing-list li.price-title {
	// font-size: 20px;
	// line-height: 24px;
	// font-weight: 700;
	letter-spacing: 1px;
	// color: #071e55;
}

ul.pricing-list li.price-value {
	font-family: dosis, sans-serif;
	// font-size: 70px;
	// line-height: 70px;
	display: block;
	// margin-top: 20px;
	// margin-bottom: 10px;
	// color: #071e55;
}

ul.pricing-list li.price-subtitle {
	font-family: open sans, sans-serif;
	margin-bottom: 30px;
	font-size: 16px;
	line-height: 24px;
	// font-weight: 600;
	// color: #6a6a6a;
}

ul.pricing-list li.price-text {
	// display: block;
	font-family: open sans, sans-serif;
	text-align: left;
	font-size: 16px;
	line-height: 28px;
	// font-weight: 400;
	margin-bottom: 5px;
}

.price-box .price-subtitle, .price-box .price-text {
	// color: #6a6a6a;
}

ul.pricing-list > li.price-tag-line a {
	color: #f5c21a;
	background: 0 0;
	border: 1px solid #1e90ff;
	border-radius: 5px 5px;
	padding: 15px 30px;
	display: inline-block;
	font-size: 15px;
	line-height: 24px;
	// font-weight: 600;
	margin: 30px 0 5px;
	transition: all .3s ease-in-out;
	-webkit-transition: all .3s ease-in-out;
}

ul.pricing-list li.price-tag a {
	color: #fff;
	background: #f5c21a;
	border: 1px solid #1e90ff;
	border-radius: 5px 5px;
	padding: 15px 30px;
	display: inline-block;
	font-size: 15px;
	line-height: 24px;
	font-weight: 600;
	margin: 30px 0 5px;
	transition: all .3s ease-in-out;
	-webkit-transition: all .3s ease-in-out;
}

ul.pricing-list li.price-tag-line a:hover, ul.pricing-list li.price-tag a:hover {
	color: #fff;
	background: #f5c21a;
	border: 1px solid #037aef;
}

.ribbon {
	position: absolute;
	right: 8px;
	top: -5px;
	z-index: 1;
	// overflow: hidden;
	width: 75px;
	height: 75px;
	text-align: right;
}

.blue {
	color: #017df7;
}

.ribbon {
	span {
		&:before {
			// content: '';
			position: absolute;
			left: 0;
			top: 100%;
			z-index: -1;
			border-left: 3px solid #79a70a;
			border-right: 3px solid transparent;
			border-bottom: 3px solid transparent;
			border-top: 3px solid #79a70a;
			// border-left-color: #1e5799;
			// border-top-color: #1e5799;
		}
	}
}

.ribbon span::after {
	// content: '';
	position: absolute;
	right: 0%;
	top: 100%;
	z-index: -1;
	border-right: 3px solid #79a70a;
	border-left: 3px solid transparent;
	border-bottom: 3px solid transparent;
	border-top: 3px solid #79a70a;
	border-right-color: #1e5799;
	border-top-color: #1e5799;
}

.ribbon span {
	font-size: 10px;
	color: #fff;
	text-transform: uppercase;
	text-align: center;
	font-weight: 700;
	line-height: 20px;
	transform: rotate(0deg);
	width: 71px;
	// width: 100px;
	display: block;
	// box-shadow: 0 3px 10px -5px #000;
	position: absolute;
	top: 19px;
	right: 7px;
	// right: -21px;
	background-color: #E8B105;
}

.card-side-4 {
	background: $production-theme;
}
</style>
